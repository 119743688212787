<!--
    Список всіх баг-репортів
-->

<template>

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />
    
</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу

export default {
    components: {
        PageHeader
    },
    data() {
        return {
            title: this.$t('Баг-репорти'), // Заголовок
        }
    }
}

</script>